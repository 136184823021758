/** @format */

import { Injectable } from '@angular/core';
import { PlatformService } from '../services';
import { CanMatch, Router, UrlTree } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class LoadingGuardMatch implements CanMatch {
  constructor(private router: Router, private platformService: PlatformService) {}

  canMatch(): UrlTree | boolean {
    if (this.platformService.isServer()) {
      return this.router.createUrlTree(['/loading']);
    } else {
      return true;
    }
  }
}
