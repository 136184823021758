/** @format */

import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from '../../core';
import { tap } from 'rxjs/operators';

@Directive({
  selector: '[appAuthed]'
})
export class AppAuthedDirective implements OnInit, OnDestroy {
  isAuthenticated$?: Subscription;

  @Input()
  set appAuthed(authed: boolean) {
    this.authed = authed;
  }

  authed: boolean = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private userService: UserService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.isAuthenticated$ = this.userService.isAuthenticated
      .pipe(tap(() => this.viewContainerRef.clear()))
      .subscribe((isAuthenticated: boolean) => {
        if (this.authed ? isAuthenticated : !isAuthenticated) {
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainerRef.clear();
        }
      });
  }

  ngOnDestroy(): void {
    this.isAuthenticated$?.unsubscribe();
  }
}
