/** @format */

import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule, PlatformService } from './core';
import { SharedModule } from './shared';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeRu from '@angular/common/locales/ru';
import * as Sentry from '@sentry/angular-ivy';
import { registerLocaleData } from '@angular/common';
import { Router } from '@angular/router';

registerLocaleData(localeRu);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    CoreModule,
    SharedModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      // TODO: For debug SSR issues
      // useFactory: (platformService: PlatformService) => {
      //   return () => {
      //     return new Promise<void>(resolve => {
      //       if (platformService.isBrowser()) {
      //         console.log('Emulate long initialization - started');
      //
      //         setTimeout(() => {
      //           console.log('Emulate long initialization - completed');
      //
      //           resolve();
      //         }, 10000);
      //       } else {
      //         resolve();
      //       }
      //     });
      //   };
      // },
      // deps: [PlatformService, Sentry.TraceService],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'ru'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
