/** @format */

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { CookieService } from '../services';
import { Observable } from 'rxjs';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headersConfig: any = {
      ['Accept']: 'application/json',
      ['Platform']: 'web'
    };

    const officeToken = this.cookieService.getItem('tokenStoryPortUser');

    if (officeToken) {
      headersConfig = {
        ...headersConfig,
        ['Authorization']: 'Bearer '.concat(officeToken)
      };
    }

    const request = req.clone({ setHeaders: headersConfig });

    return next.handle(request);
  }
}
