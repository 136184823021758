/** @format */

import { Component, OnInit } from '@angular/core';
import { CookieService } from '../../../core';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html'
})
export class CookieComponent implements OnInit {
  cookieToggle: boolean = false;

  constructor(private cookieService: CookieService) {}

  ngOnInit(): void {
    this.cookieToggle = !Boolean(this.cookieService.getItem('isAcceptCookie'));
  }

  onAcceptCookies(): void {
    const UTCDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toUTCString();

    this.cookieService.setItem('isAcceptCookie', '1', { expires: UTCDate });

    this.cookieToggle = !Boolean(this.cookieService.getItem('isAcceptCookie'));
  }
}
