/** @format */

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable()
export class FeedbackService {
  constructor(private apiService: ApiService) {}

  postFeedback(body: any): Observable<any> {
    return this.apiService.post('/v1/feedback', body);
  }
}
