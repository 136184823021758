/** @format */

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map, Observable } from 'rxjs';
import { LastPayment, PaymentOrder } from '../models';

@Injectable()
export class PaymentsService {
  constructor(private apiService: ApiService) {}

  postCreatePayment(orderUuid: string): Observable<PaymentOrder> {
    return this.apiService
      .post('/v1/payments/' + orderUuid)
      .pipe(map((response: any) => response.data));
  }

  getStatus(paymentUuid: string): Observable<{ status: string }> {
    return this.apiService
      .get('/v1/payments/' + paymentUuid)
      .pipe(map((response: any) => response.data));
  }

  getStatusOfLastPayment(): Observable<LastPayment | null> {
    return this.apiService.get('/v1/payments/last').pipe(map((response: any) => response.data));
  }
}
