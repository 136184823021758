/** @format */

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { PlatformService, User, UserService } from '../../../core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit, OnDestroy {
  user?: User;
  user$?: Subscription;
  currentYear: number = new Date().getFullYear();

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private router: Router,
    private platformService: PlatformService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.user$ = this.userService.currentUser.subscribe((user: User) => (this.user = user));
  }

  ngOnDestroy(): void {
    [this.user$].forEach($ => $?.unsubscribe());
  }

  onCopy(text: string): void {
    if (this.user?.id) {
      this.router.navigate(['/help']).then(() => console.debug('Route changed'));
    } else {
      if (this.platformService.isBrowser()) {
        const window: Window = this.platformService.getWindow();

        if (!window.navigator.clipboard) {
          const textArea: HTMLTextAreaElement = this.document.createElement('textarea');
          textArea.value = text;

          // Avoid scrolling to bottom

          textArea.style.top = '0';
          textArea.style.left = '0';
          textArea.style.position = 'fixed';

          this.document.body.appendChild(textArea);

          textArea.focus();
          textArea.select();

          this.document.execCommand('copy');
          this.document.body.removeChild(textArea);

          return;
        }

        window.navigator.clipboard.writeText(text).then(() => console.debug('writeText'));
      }
    }
  }
}
