<!-- @format -->

<div
	class="bg-secondary-2 fixed bottom-0 z-10 mx-auto flex w-full max-w-[1920px] items-center justify-between gap-4 space-x-1 rounded-t-lg p-4"
	*ngIf="cookieToggle"
>
	<div class="flex flex-row flex-wrap items-center justify-start gap-2">
		<p class="text-caption-2-regular text-on-light-1">
			Мы используем куки-файлы для более быстрого и удобного использования сайта
		</p>
		<a
			class="text-caption-2-semibold text-primary"
			href="/assets/docs/user_agreement.docx"
			download
		>
			Узнайте больше
		</a>
	</div>
	<button class="btn btn-primary btn-sm btn-inline" (click)="onAcceptCookies()">
		<span class="text-control-2-semibold">Ок, принять</span>
	</button>
</div>
