/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { HelperService } from '../../core';

@Pipe({
  name: 'plural'
})
export class PluralPipe implements PipeTransform {
  constructor(private helperService: HelperService) {}

  transform(n: number, list: string[]): string {
    return this.helperService.getPlural(list, n);
  }
}
