/**
 *
 * Window code snippet is based on
 * https://juristr.com/blog/2016/09/ng2-get-window-ref/
 *
 * @format
 */

import {
  Inject,
  Injectable,
  LOCALE_ID,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

const getWindow = (): Window => window;

@Injectable()
export class PlatformService {
  isShownOverlay = new BehaviorSubject<boolean>(false);

  loader$ = new BehaviorSubject<boolean>(false);

  renderer2: Renderer2;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) public locale: string,
    private rendererFactory2: RendererFactory2
  ) {
    this.renderer2 = rendererFactory2.createRenderer(null, null);
  }

  isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  isServer(): boolean {
    return !this.isBrowser();
  }

  getWindow(): Window {
    return getWindow();
  }

  setScrollToggle(toggle: boolean): void {
    if (this.isBrowser()) {
      const window = this.getWindow();
      const className = 'overlay';

      if (this.document.body.clientHeight > window.innerHeight) {
        this.renderer2[toggle ? 'addClass' : 'removeClass'](this.document.body, className);
      } else {
        this.renderer2.removeClass(this.document.body, className);
      }
    }
  }

  setViewHeight() {
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    if (this.isBrowser()) {
      const window = this.getWindow();
      const setHeight = () => {
        const vh = window.innerHeight * 0.01;
        this.document.documentElement.style.setProperty('--vh', `${vh}px`);
      };

      setHeight();

      window.addEventListener('resize', () => {
        setHeight();
      });
    }
  }
}
