/** @format */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { APP_CONFIG } from './app/core';
import * as Sentry from '@sentry/angular-ivy';

const sentryRates: number = 0.1;

Sentry.init({
  dsn: 'https://ed38529bea2b24e538ae0f366ca42741@o4505310654693376.ingest.sentry.io/4506653834870784',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/audio\.litnet\.com/]
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  sampleRate: sentryRates,
  tracesSampleRate: sentryRates,
  replaysSessionSampleRate: sentryRates,
  replaysOnErrorSampleRate: sentryRates
});

function bootstrap() {
  fetch('./assets/configurations/config.json')
    .then((response: any) => response.json())
    .then((response: any) => {
      const config: any = JSON.parse(atob(response.configuration));

      if (config.production) {
        enableProdMode();
      }

      const appConfigProvider: any = {
        provide: APP_CONFIG,
        useValue: config
      };

      platformBrowserDynamic([appConfigProvider])
        .bootstrapModule(AppModule)
        .catch(err => console.error(err));
    });
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
