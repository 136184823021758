<!-- @format -->

<footer class="bg-light-2 bottom-bar-shadow">
	<div
		class="mx-auto flex w-full max-w-[1560px] flex-col gap-3 p-4 md:flex-row md:items-start md:justify-between md:p-6"
	>
		<div class="flex items-center justify-between gap-8">
			<a routerLink="/">
				<img src="/assets/images/shared/logo-2.svg" alt="Литнет" loading="lazy" />
			</a>
			<div class="text-on-light-1 flex items-center space-x-2.5">
				<span class="text-body-2-regular">© {{ currentYear }}, Литнет</span>
				<!--
				<a href="https://vk.com">
            <i appSvgIcon appIcon="vk-logo" appSquare="20"></i>
        </a>
        -->
			</div>
		</div>
		<button
			class="border-primary flex flex-col items-center justify-center gap-1 rounded-lg border-2 bg-white p-3 md:flex-row md:gap-2 md:p-4"
			(click)="onCopy('help@lit-era.com')"
		>
			<span id="support-email" class="text-body-1-semibold text-primary">
				help@lit-era.com
			</span>
			<span class="text-body-2-regular text-on-light-1">техническая поддержка</span>
		</button>
	</div>
	<div class="border-light-3 border-t">
		<div class="mx-auto grid max-w-[1560px] grid-cols-3 gap-1 px-4 py-2 md:px-6">
			<div class="col-span-3 flex flex-wrap gap-1 md:col-span-1 md:text-left">
				<span class="text-body-2-regular text-on-light-1">ООО «Литнет»</span>
				<span class="text-body-2-regular text-on-light-1">ИНН: 9704145094</span>
				<span class="text-body-2-regular text-on-light-1">ОГРН: 1227700355563</span>
			</div>
			<a
				class="text-body-2-regular text-on-light-1 col-span-3 underline md:col-span-1 md:text-center"
				[routerLink]="['/user-agreement']"
			>
				Пользовательское соглашение
			</a>
			<a
				class="text-body-2-regular text-on-light-1 col-span-3 underline md:col-span-1 md:text-right"
				[routerLink]="['/privacy-policy']"
			>
				Политика конфиденциальности
			</a>
		</div>
	</div>
</footer>
