/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import {
  AvatarComponent,
  CookieComponent,
  ErrorTooltipComponent,
  FooterComponent,
  HeaderComponent,
  LoaderComponent,
  OverlayComponent,
  SnackbarComponent,
  SvgIconComponent
} from './components';
import { AppAuthedDirective } from './directives';
import { PluralPipe } from './pipes';

const components: any[] = [
  AvatarComponent,
  CookieComponent,
  ErrorTooltipComponent,
  FooterComponent,
  HeaderComponent,
  LoaderComponent,
  OverlayComponent,
  SnackbarComponent,
  SvgIconComponent
];
const directives: any[] = [AppAuthedDirective];
const pipes: any[] = [PluralPipe];

@NgModule({
  declarations: [...components, ...directives, ...pipes],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    NgxMaskModule,
    ...components,
    ...directives,
    ...pipes
  ]
})
export class SharedModule {}
