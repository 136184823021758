/** @format */

import { NgModule } from '@angular/core';
import {
  ApiService,
  CookieService,
  CouponService,
  FeedbackService,
  HelperService,
  PaymentsService,
  PlatformService,
  SnackbarService,
  SubscriptionService,
  UserService
} from './services';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './interceptors';

@NgModule({
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    ApiService,
    CookieService,
    CouponService,
    FeedbackService,
    HelperService,
    PaymentsService,
    PlatformService,
    SnackbarService,
    SubscriptionService,
    UserService
  ]
})
export class CoreModule {}
