/** @format */

import { Injectable } from '@angular/core';
import { Snack, SnackOptions } from '../models';
import { delay, of, ReplaySubject } from 'rxjs';

@Injectable()
export class SnackbarService {
  snackbar$ = new ReplaySubject<Snack>();

  show(message: string, snackOptions?: SnackOptions): void {
    const delay$ = of(true)
      .pipe(delay(200))
      .subscribe(() => {
        this.snackbar$.next({
          message,
          options: {
            timeout: 3000,
            ...snackOptions
          }
        });

        delay$.unsubscribe();
      });
  }
}
