/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { User, UserService } from '../../../core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  user?: User;
  user$?: Subscription;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.user$ = this.userService.currentUser.subscribe((user: User) => (this.user = user));
  }

  ngOnDestroy(): void {
    [this.user$].forEach($ => $?.unsubscribe());
  }

  onLogin(): void {
    this.userService.getLitnetIdAuthToken();
  }
}
