/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PlatformService } from '../../../core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html'
})
export class LoaderComponent implements OnInit {
  @Input() appLoading: boolean = false;

  loader$!: BehaviorSubject<boolean>;

  constructor(private platformService: PlatformService) {}

  ngOnInit(): void {
    this.loader$ = this.platformService.loader$;
  }
}
