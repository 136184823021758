/** @format */

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map, Observable } from 'rxjs';
import { Coupon } from '../models';

@Injectable()
export class CouponService {
  constructor(private apiService: ApiService) {}

  getCoupon(code: string): Observable<Coupon> {
    return this.apiService.get('/v1/coupons/' + code).pipe(map((response: any) => response.data));
  }
}
