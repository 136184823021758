/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Snack } from '../../../core/models';
import { SnackbarService } from '../../../core';
import { snackAnimation } from '../../../app-animations';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  animations: [snackAnimation]
})
export class SnackbarComponent implements OnInit, OnDestroy {
  snackbar$?: Subscription;
  snackbarList: Snack[] = [];

  constructor(private snackbarService: SnackbarService) {}

  ngOnInit() {
    this.snackbar$ = this.snackbarService.snackbar$.subscribe((snack: Snack) => {
      this.onPush({
        id: Date.now(),
        ...snack
      });
    });
  }

  ngOnDestroy() {
    [this.snackbar$].forEach($ => $?.unsubscribe());
  }

  onPush(snack: Snack): void {
    this.snackbarList.push(snack);

    if (snack.options?.timeout !== 0) {
      const timeout = setTimeout(() => {
        this.onClose(snack?.id as number);

        clearTimeout(timeout);
      }, snack.options?.timeout);
    }
  }

  onClose(snackId: number): void {
    this.snackbarList = this.snackbarList.filter((snack: Snack) => snack?.id !== snackId);
  }
}
