/** @format */

import { Injectable } from '@angular/core';
import { CanMatch, Router } from '@angular/router';
import { CookieService, SnackbarService, UserService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardMatch implements CanMatch {
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private snackbarService: SnackbarService,
    private userService: UserService
  ) {}

  canMatch(): boolean {
    if (!!this.cookieService.getItem('tokenStoryPortUser')) {
      return true;
    } else {
      this.userService.removeAuthorization();

      this.router.navigate(['/']).then(() => {
        this.snackbarService.show('Нет доступа. Авторизуйтесь', {
          icon: 'warning'
        });
      });

      return false;
    }
  }
}
