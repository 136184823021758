/** @format */

import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  distinctUntilChanged,
  map,
  Observable,
  ReplaySubject,
  throwError
} from 'rxjs';
import { ApiService } from './api.service';
import { catchError, tap } from 'rxjs/operators';
import { User } from '../models';
import { CookieService } from './cookie.service';
import { PlatformService } from './platform.service';

@Injectable()
export class UserService {
  currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>({} as User);
  currentUser: Observable<User> = this.currentUserSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  isAuthenticatedSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  isAuthenticated: Observable<boolean> = this.isAuthenticatedSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private cookieService: CookieService,
    private platformService: PlatformService
  ) {}

  getAuthorization(): void {
    if (this.cookieService.getItem('tokenStoryPortUser')) {
      this.platformService.loader$.next(true);

      this.apiService
        .get('/v2/profile')
        .pipe(
          map((response: any) => response.data),
          catchError((error: any) => {
            this.removeAuthorization();

            this.platformService.loader$.next(false);

            return throwError(error);
          })
        )
        .subscribe((user: User) => {
          this.setAuthorization(user);

          this.platformService.loader$.next(false);
        });
    } else {
      this.removeAuthorization();
    }
  }

  setAuthorization(user: User): void {
    if (user.token) {
      this.cookieService.setItem('tokenStoryPortUser', user.token, {
        ['max-age']: 2592000
      });
    }

    /** SET currentUser */

    this.currentUserSubject.next(user);
    this.isAuthenticatedSubject.next(true);
  }

  removeAuthorization(): void {
    this.cookieService.removeItem('tokenStoryPortUser');

    /** SET currentUser */

    this.currentUserSubject.next({} as User);
    this.isAuthenticatedSubject.next(false);
  }

  getLitnetIdAuthToken(): void {
    this.apiService
      .get('/v1/oauth/litnet-id/redirect')
      .pipe(map((response: any) => response.data))
      .subscribe({
        next: (data: any) => {
          if (this.platformService.isBrowser()) {
            const window: Window = this.platformService.getWindow();

            window.open(data.url, '_self');
          }
        }
      });
  }

  postLitnetIdLoginByCode(body: any): Observable<User> {
    return this.apiService.post('/v1/oauth/litnet-id/auth-by-code', body).pipe(
      map((response: any) => response.data),
      tap((user: User) => this.setAuthorization(user))
    );
  }

  postLogout(): Observable<any> {
    return this.apiService.post('/v1/logout').pipe(
      tap(() => this.removeAuthorization()),
      catchError((error: any) => {
        this.removeAuthorization();

        return throwError(error);
      })
    );
  }

  postDeepLink(): Observable<string> {
    return this.apiService.post('/v1/dl').pipe(
      map((response: any) => response.data),
      map((response: any) => response.url)
    );
  }

  getDeepLink(token: string): Observable<any> {
    return this.apiService.get('/v1/dl/web/' + token).pipe(
      map((response: any) => response.data),
      tap((user: User) => this.setAuthorization(user)),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  postChangeProfile(body: any): Observable<User> {
    return this.apiService.post('/v2/profile', body).pipe(
      map((response: any) => response.data),
      tap((user: User) => {
        this.setAuthorization(user);
      })
    );
  }

  postChangePassword(body: any): Observable<any> {
    return this.apiService.post('/v1/password', body);
  }

  postChangeAvatar(body: any): Observable<any> {
    return this.apiService.post('/v2/avatar', body).pipe(
      map((response: any) => response.data),
      tap((user: User) => {
        this.setAuthorization(user);
      })
    );
  }
}
