<!-- @format -->

<div class="relative overflow-hidden rounded-full" [ngClass]="appClassList">
	<i
		*ngIf="!appUser?.avatar"
		appSvgIcon
		appIcon="person"
		[appHeight]="appSquare"
		[appWidth]="appSquare"
		appViewBox="0 0 12 12"
	></i>
	<img
    #avatarEl
		class="block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
		*ngIf="!!appUser && !!appUser?.avatar"
		[src]="appUser.avatar"
		[alt]="appUser.first_name + ' ' + appUser.last_name"
    [ngClass]="avatarEl.naturalWidth / avatarEl.naturalHeight <= 1 ? 'w-full h-auto' : 'w-auto h-full'"
	/>
</div>
