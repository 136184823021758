<!-- @format -->

<div
	*ngIf="(loader$ | async) || appLoading"
	class="bg-light-1 fixed inset-0 z-[1] flex h-screen w-screen items-center justify-center"
>
	<img
		class="h-12 w-12 animate-spin"
		src="assets/images/shared/loader.png"
		alt="loader"
	/>
</div>
