<!-- @format -->

<ng-container *ngIf="state$.getValue()">
	<div
		class="overlay h-screen-fix bg-dark-3 fixed inset-0 z-30 mx-auto grid w-screen overflow-auto p-4"
		[ngClass]="classList"
		(click)="onClick($event)"
		[@overlayAnimation]="state$.getValue()"
	>
		<div class="self-center justify-self-center">
			<ng-content></ng-content>
		</div>
	</div>
</ng-container>
