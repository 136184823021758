<!-- @format -->

<div
	*ngIf="snackbarList.length"
	class="fixed left-1/2 top-1.5 z-30 flex w-screen max-w-[428px] -translate-x-1/2 flex-col items-center justify-center space-y-2 px-2"
>
	<div
		class="snackbar-shadow bg-secondary-3 flex w-full items-center space-x-3 rounded-lg p-4"
		*ngFor="let snack of snackbarList"
		[@snackAnimation]
	>
		<ng-container *ngIf="snack.options?.icon as icon" [ngSwitch]="icon">
			<i
				class="text-success"
				*ngSwitchCase="'done'"
				appSvgIcon
				[appIcon]="icon"
				appSquare="24"
			></i>
			<i
				class="text-error"
				*ngSwitchCase="'warning'"
				appSvgIcon
				[appIcon]="icon"
				appSquare="24"
			></i>
		</ng-container>
		<span class="text-caption-1-semibold text-on-light-1">
			{{ snack.message }}
		</span>
	</div>
</div>
