/** @format */

import { animate, style, transition, trigger } from '@angular/animations';

export const snackAnimation = trigger('snackAnimation', [
  transition(
    ':enter',
    [
      style({ transform: 'translateY(-100%)', opacity: 0 }),
      animate(
        '{{duration}}s {{delay}}s cubic-bezier(0.785, 0.135, 0.15, 0.86)',
        style({ transform: 'translateY(0)', opacity: 1 })
      )
    ],
    {
      params: {
        duration: 0.2,
        delay: 0
      }
    }
  ),
  transition(
    ':leave',
    [
      style({ transform: 'translateY(0)', opacity: 1 }),
      animate(
        '{{duration}}s {{delay}}s cubic-bezier(0.785, 0.135, 0.15, 0.86)',
        style({ transform: 'translateY(-100%)', opacity: 0 })
      )
    ],
    {
      params: {
        duration: 0.2,
        delay: 0
      }
    }
  )
]);

export const overlayAnimation = trigger('overlayAnimation', [
  transition(
    ':enter',
    [
      style({ opacity: 0 }),
      animate(
        '{{duration}}s {{delay}}s cubic-bezier(0.785, 0.135, 0.15, 0.86)',
        style({ opacity: 1 })
      )
    ],
    {
      params: {
        duration: 0.2,
        delay: 0
      }
    }
  ),
  transition(
    ':leave',
    [
      style({ opacity: 1 }),
      animate(
        '{{duration}}s {{delay}}s cubic-bezier(0.785, 0.135, 0.15, 0.86)',
        style({ opacity: 0 })
      )
    ],
    {
      params: {
        duration: 0.2,
        delay: 0
      }
    }
  )
]);
