/** @format */

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PlatformService, UserService } from './core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('rootElement') rootElement!: ElementRef;

  routerEvents$!: Subscription;

  constructor(
    private userService: UserService,
    private platformService: PlatformService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.platformService.isBrowser()) {
      this.userService.getAuthorization();
    }

    this.platformService.setViewHeight();

    this.routerEvents$ = this.router.events
      // @ts-ignore
      .pipe(filter((routerEvent: RouterEvent) => routerEvent instanceof NavigationEnd))
      .subscribe(() => {
        // делаем скролл блока, т.к. высота body === 100vh и скролл === 0. См. класс overflow-y-auto
        this.rootElement.nativeElement.scrollTop = 0;
      });
  }

  ngOnDestroy(): void {
    [this.routerEvents$].forEach($ => $?.unsubscribe());
  }
}
