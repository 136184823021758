const Yookassa = new (function () {
  const instance = this

  this.getYookassa = () => {
    return new Promise((resolve, reject) => {
      const d = document
      const w = window

      const init = () => {
        const yookassa = window.YooMoneyCheckoutWidget || null

        if (yookassa) {
          resolve(yookassa)
        } else {
          reject('Error: "Not found instance YooMoneyCheckoutWidget"')
        }
      }

      if (d.readyState === "complete") {
        init()
      } else {
        if (w.attachEvent) {
          w.attachEvent("onload", init)
        } else {
          w.addEventListener("load", init, false)
        }
      }
    })
  }

  return function () {
    return instance
  }
})()

let yookassa = new Yookassa()

export default yookassa
